import React from "react";
import './Stores.scss';
import google from "../../resources/img/google.png";
import apple from "../../resources/img/apple.png";
import googleHover from '../../resources/img/google_hover.png';
import appleHover from '../../resources/img/apple_hover.png';
import useImagePreloader from "../../utils/imagePreloader";

const preloadSrcList: string[] = [
  googleHover,
  appleHover,
]

export function Stores() {

  useImagePreloader(preloadSrcList);

  return (
    <div id="store" className="center">
      <a id="google" className="imaged" href="https://play.google.com/store/apps/details?id=it.papalillo.moviestowatch">
        <img className="store-badge" alt="Google Play" title="Google Play" src={google} />
      </a>
      <a id="apple" className="imaged" href="https://itunes.apple.com/app/cinemaniac/id1450924201">
        <img className="store-badge" alt="App Store" title="App Store" src={apple} />
      </a>
    </div>
  );
}
