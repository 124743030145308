
module.exports = `
<header>
  <h1>Privacy Policy di Cinemaniac</h1>
  <p class="subtitle">Informativa sulla Privacy</p>
</header>

<div class="summary">
  <p>Cinemaniac raccoglie alcuni Dati Personali dei propri Utenti.</p>
  <p>Questo documento può essere stampato utilizzando il comando di stampa presente nelle impostazioni di qualsiasi browser.</p>

  <p>Per utilizzare Cinemaniac, si deve dare il consenso esplicito per la raccolta e l'uso delle informazioni descritte in questa informativa. Le informazioni personali che vengono raccolte sono utilizzate per fornire e migliorare Cinemaniac. Il titolare non userà né condividerà le informazioni con nessuno ad eccezione di quanto descritto nella presente Privacy Policy.</p>
  <p>La non accettazione della presente Informativa sulla Privacy comporta l'impossibilità di poter fruire dei contenuti all'interno di Cinemaniac.</p>

  <h2>Informativa breve</h2>
  <p>Dati personali raccolti per le seguenti finalità ed utilizzando i seguenti servizi:</p>
  <ul>
    <li>
      <strong>Registrazione ed autenticazione</strong>: Autenticazione tramite Firebase, Google e Apple
      <p class="secondary">Dati Personali: indirizzo e-mail e identificativo account (ID) dell'utente. Questi dati sono necessari in caso di iscrizione/accesso espliciti a Cinemaniac, per identificare l'utente al fine di salvare in modo sicuro i dati dell'utente nel server e fornire la stessa esperienza personalizzata su tutti i dispositivi dell'utente. Altri tipi di dati utilizzabili specificati nelle informative sulla privacy dei rispettivi servizi non sono richiesti da Cinemaniac, non vengono altresì memorizzati nel server Cinemaniac.</p>
    </li>
    <li>
      <strong>Statistica</strong>: Firebase Analytics
      <p class="secondary">Dati Personali: apertura dell'applicazione, dati di utilizzo, durata della sessione, informazioni sul dispositivo, numero di sessioni, primi avvii, sistemi operativi e registrazione eventi solo a fini statistici.</p>
    </li>
    <li>
      <strong>Monitoraggio dell'infrastruttura</strong>: Firebase Crashlytics
      <p class="secondary">Dati Personali: Identificativo univoco universale (UUID); Informazioni sui malfunzionamenti; informazioni sul dispositivo. Questi dati sono richiesti dal Titolare per ottenere approfondimenti sulle prestazioni e la stabilità di Cinemaniac.</p>
    </li>
    <li>
      <strong>Pubblicità</strong>: AdMob
      <p class="secondary">Dati Personali: Cookie, dati di utilizzo, identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID o identificatore IDFA, per esempio)</p>
    </li>
  </ul>
</div>

<hr />

<div class="full-policy">
  <h2>Informativa completa</h2>

  <h3>Titolare del Trattamento dei Dati</h3>
  <p>Antonio Papalillo <span class="secondary owner-email"></span></p>

  <h3>Tipologie di Dati raccolti</h3>
  <p>Fra i Dati Personali raccolti da Cinemaniac, in modo autonomo o tramite terze parti, ci sono: email; Cookie; Dati di utilizzo; identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID o identificatore IDFA, per esempio); Identificativo univoco universale (UUID); Informazioni sui malfunzionamenti; informazioni sul dispositivo; numero di sessioni; durata della sessione; apertura dell'Applicazione; primi avvii; sistemi operativi.</p>
  <p>Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi.</p>
  <p>I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di Cinemaniac.</p>
  <p>Se non diversamente specificato, tutti i Dati richiesti da Cinemaniac sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per Cinemaniac fornire il Servizio. Nei casi in cui Cinemaniac indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.</p>
  <p>L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di Cinemaniac o dei titolari dei servizi terzi utilizzati da Cinemaniac, ove non diversamente precisato, ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile.</p>
  <p>L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante Cinemaniac e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.</p>

  <h3>Modalità e luogo del trattamento dei Dati raccolti</h3>
  <h4>Modalità di trattamento</h4>
  <p>Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.</p>
  <p>Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di Cinemaniac (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.</p>

  <h4>Base giuridica del trattamento</h4>
  <p>Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:</p>
  <ul>
    <li>l’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni ordinamenti il Titolare può essere autorizzato a trattare Dati Personali senza che debba sussistere il consenso dell’Utente o un’altra delle basi giuridiche specificate di seguito, fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione dei Dati Personali;</li>
    <li>il trattamento è necessario all'esecuzione di un contratto con l’Utente e/o all'esecuzione di misure precontrattuali;</li>
    <li>il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;</li>
    <li>il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare;</li>
    <li>il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.</li>
  </ul>
  <p>È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.</p>

  <h4>Luogo</h4>
  <p>I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate.</p>
  <p>I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.</p>
  <p>L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati.</p>
  <p>L’Utente può verificare se abbia luogo uno dei trasferimenti appena descritti esaminando la sezione di questo documento relativa ai dettagli sul trattamento di Dati Personali o chiedere informazioni al Titolare contattandolo agli estremi riportati in apertura.</p>

  <h4>Periodo di conservazione</h4>
  <p>I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti.
  <p>Pertanto:
  <ul>
    <li>I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.</li>
    <li>I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.</li>
  </ul>
  <p>Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità.</p>
  <p>Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.</p>

  <h3>Finalità del Trattamento dei Dati raccolti</h3>
  <p>I Dati dell’Utente sono raccolti per consentire al Titolare di fornire il Servizio, adempiere agli obblighi di legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed interessi (o quelli di Utenti o di terze parti), individuare eventuali attività dolose o fraudolente, nonché per le seguenti finalità: Registrazione ed autenticazione, Gestione contatti e invio di messaggi, Accesso agli account su servizi terzi, Pubblicità, Monitoraggio dell'infrastruttura e Statistica.</p>
  <p>Per ottenere informazioni dettagliate sulle finalità del trattamento e sui Dati Personali trattati per ciascuna finalità, l’Utente può fare riferimento alla sezione “Informazioni dettagliate sul trattamento dei Dati Personali”.</p>

  <h3>Dettagli sul trattamento dei Dati Personali</h3>
  <p>I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:</p>
  <ul>
    <li>
      <h4>Registrazione ed autenticazione</h4>
      <p class="secondary">Con la registrazione o l’autenticazione l’Utente consente a Cinemaniac di identificarlo e di dargli accesso a servizi dedicati.
        A seconda di quanto indicato di seguito, i servizi di registrazione e di autenticazione potrebbero essere forniti con l’ausilio di terze parti. Qualora questo avvenga, Cinemaniac potrà accedere ad alcuni Dati conservati dal servizio terzo usato per la registrazione o l’identificazione.<br />Alcuni dei servizi di seguito indicati potrebbero raccogliere Dati Personali anche per fini di targeting e profilazione; per saperne di più, si prega di fare riferimento alla descrizione di ciascun servizio.</p>

      <h5>Firebase Authentication</h5>
      <p class="secondary">Firebase Authentication è un servizio di registrazione ed autenticazione fornito da Google LLC oppure da Google Ireland Limited, a seconda della posizione in cui Cinemaniac viene utilizzata. Per semplificare il processo di registrazione ed autenticazione, Firebase Authentication può utilizzare fornitori di identità di terze parti e salvare le informazioni sulla propria piattaforma.</p>
      <p class="secondary">Dati Personali trattati: indirizzo e-mail e identificativo account (ID) dell'utente.<br />Luogo del trattamento: Stati Uniti – <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>; Irlanda – <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>

      <h5>Google OAuth</h5>
      <p class="secondary">Google OAuth è un servizio di registrazione ed autenticazione fornito da Google LLC oppure da Google Ireland Limited, a seconda della posizione in cui Cinemaniac viene utilizzata, e collegato al network Google.</p>
      <p class="secondary">Dati Personali trattati: indirizzo e-mail e identificativo account (ID) dell'utente.<br />Luogo del trattamento: Stati Uniti – <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>; Irlanda – <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>

      <h5>Apple authentication (Apple, Inc.)</h5>
      <p class="secondary">Apple Authentication è un servizio di registrazione ed autenticazione fornito da Apple, Inc.</p>
      <p class="secondary">Dati Personali trattati: indirizzo e-mail e identificativo account (ID) dell'utente.<br />Luogo del trattamento: Stati Uniti – <a href="https://www.apple.com/it/legal/privacy/data/it/sign-in-with-apple/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
    </li>
    <li>
      <h4>Statistica</h4>
      <p class="secondary">I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.</p>

      <h5>Google Analytics for Firebase</h5>
      <p class="secondary">Google Analytics per Firebase, o Firebase Analytics, è un servizio di analisi fornito da Google LLC oppure da Google Ireland Limited, a seconda della posizione in cui Cinemaniac viene utilizzata.</p>
      <p class="secondary">Per una comprensione dell'utilizzo dei dati da parte di Google, si prega di consultare le <a href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noopener">norme per i partner di Google</a>.</p>
      <p class="secondary">Firebase Analytics potrebbe condividere Dati con altri servizi forniti da Firebase tra cui, ad esempio, Crash Reporting, Authentication, Remote Config o Notifications. L’utente può consultare questa privacy policy per avere una descrizione dettagliata degli altri strumenti usati dal Titolare.</p>
      <p class="secondary">Per permettere il funzionamento di Firebase Analytics, Cinemaniac usa alcuni identificatori per periferiche mobili ovvero tecnologie simili ai cookie.</p>
      <p class="secondary">Dati Personali trattati: apertura dell'Applicazione; Dati di utilizzo; durata della sessione; informazioni sul dispositivo; numero di sessioni; primi avvii; sistemi operativi.<br />
        Luogo del trattamento: Stati Uniti – <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>; Irlanda – <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
    </li>
    <li>
      <h4>Monitoraggio dell'infrastruttura</h4>
      <p class="secondary">Questo tipo di servizi permette a Cinemaniac di monitorare l’utilizzo ed il comportamento di componenti della stessa, per consentirne il miglioramento delle prestazioni e delle funzionalità, la manutenzione o la risoluzione di problemi.</p>

      <h5>Crashlytics</h5>
      <p class="secondary">Crashlytics è un servizio di monitoraggio fornito da Google LLC oppure da Google Ireland Limited, a seconda della posizione in cui Cinemaniac viene utilizzata.</p>
      <p class="secondary">Dati Personali trattati: Identificativo univoco universale (UUID); Informazioni sui malfunzionamenti; informazioni sul dispositivo.<br />
        Luogo del trattamento: Stati Uniti – <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>; Irlanda – <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
    </li>
    <li>
      <h4>Pubblicità</h4>
      <p class="secondary">Questo tipo di servizi consente di utilizzare i Dati dell’Utente per finalità di comunicazione commerciale. Queste comunicazioni sono mostrate su Cinemaniac sotto forma di banner e altre forme pubblicitarie, anche in relazione agli interessi dell’Utente.</p>
      <p class="secondary">Ciò non significa che tutti i Dati Personali vengano utilizzati per questa finalità. Dati e condizioni di utilizzo sono indicati di seguito.</p>
      <p class="secondary">Alcuni dei servizi di seguito indicati potrebbero utilizzare Cookie o altri Identificatori per identificare l’Utente o utilizzare la tecnica del behavioral retargeting, ossia visualizzare annunci pubblicitari personalizzati in base agli interessi e al comportamento dell’Utente, rilevati anche al di fuori di Cinemaniac. Per avere maggiori informazioni in merito, ti suggeriamo di verificare le informative privacy dei rispettivi servizi.</p>
      <p class="secondary">In aggiunta alle funzionalità di opt-out offerte dai servizi di seguito riportati, l'Utente può effettuare l’opt-out visitando la <a href="http://optout.networkadvertising.org/?c=1" target="_blank" rel="noopener">pagina di opt-out della Network Advertising Initiative</a>.</p>

      <p class="secondary">Gli Utenti possono anche scegliere di non partecipare a determinate funzionalità pubblicitarie attraverso le corrispondenti opzioni di configurazione del dispositivo, come le opzioni di configurazione pubblicitaria del dispositivo mobile o la configurazione pubblicitaria generica.</p>

      <h5>AdMob</h5>
      <p class="secondary">AdMob è un servizio di advertising fornito da Google LLC oppure da Google Ireland Limited, a seconda della posizione in cui Cinemaniac viene utilizzata.</p>
      <p class="secondary">Per una comprensione dell'utilizzo dei dati da parte di Google, si prega di consultare le <a href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noopener">norme per i partner di Google</a>.</p>
      <p class="secondary">Dati Personali trattati: Cookie; Dati di utilizzo; identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID o identificatore IDFA, per esempio).<br />
        Luogo del trattamento: Stati Uniti – <a href="https://www.google.com/policies/technologies/ads/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> – <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener">Opt Out</a>; Irlanda – <a href="https://www.google.com/policies/technologies/ads/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
    </li>
  </ul>

  <h3>Diritti dell’Utente</h3>
  <p>Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.</p>

  <p>In particolare, l’Utente ha il diritto di:</p>

  <ul>
    <li>revocare il consenso in ogni momento. L’Utente può revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso.</li>
    <li>opporsi al trattamento dei propri Dati. L’Utente può opporsi al trattamento dei propri Dati quando esso avviene su una base giuridica diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione sottostante.</li>
    <li>accedere ai propri Dati. L’Utente ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.</li>
    <li>verificare e chiedere la rettificazione. L’Utente può verificare la correttezza dei propri Dati e richiederne l’aggiornamento o la correzione.</li>
    <li>ottenere la limitazione del trattamento. Quando ricorrono determinate condizioni, l’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.</li>
    <li>ottenere la cancellazione o rimozione dei propri Dati Personali. Quando ricorrono determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati da parte del Titolare.</li>
    <li>ricevere i propri Dati o farli trasferire ad altro titolare. L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa disposizione è applicabile quando i Dati sono trattati con strumenti automatizzati ed il trattamento è basato sul consenso dell’Utente, su un contratto di cui l’Utente è parte o su misure contrattuali ad esso connesse.</li>
    <li>proporre reclamo. L’Utente può proporre un reclamo all’autorità di controllo della protezione dei dati personali competente o agire in sede giudiziale.</li>
  </ul>

  <h4>Dettagli sul diritto di opposizione</h4>
  <p>Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.</p>
  <p>Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.</p>

  <h4>Come esercitare i diritti</h4>
  <p>Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese.</p>

  <h3>Ulteriori informazioni sul trattamento</h3>
  <h4>Difesa in giudizio</h4>
  <p>I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di Cinemaniac o dei Servizi connessi da parte dell’Utente.</p>
  <p>L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.</p>

  <h4>Informative specifiche</h4>
  <p>Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, Cinemaniac potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.</p>

  <h4>Log di sistema e manutenzione</h4>
  <p>Per necessità legate al funzionamento ed alla manutenzione, Cinemaniac e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.</p>

  <h4>Informazioni non contenute in questa policy</h4>
  <p>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.</p>

  <h4>Risposta alle richieste “Do Not Track”</h4>
  <p>Cinemaniac non supporta le richieste “Do Not Track”.</p>
  <p>Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente è invitato a consultare le rispettive privacy policy.</p>

  <h4>Modifiche a questa privacy policy</h4>
  <p>Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento notificandolo agli Utenti su questa pagina e, se possibile, su Cinemaniac nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso. Si prega dunque di consultare con frequenza questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.</p>
  <p>Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.</p>
</div>

<hr />

<div class="definitions">
  <h3>Definizioni e riferimenti legali</h3>

  <h4>Dati Personali (o Dati)</h4>
  <p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.</p>

  <h4>Dati di Utilizzo</h4>
  <p>Sono le informazioni raccolte automaticamente attraverso Cinemaniac (anche da applicazioni di parti terze integrate in Cinemaniac), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con Cinemaniac, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.</p>

  <h4>Utente</h4>
  <p>L'individuo che utilizza Cinemaniac che, salvo ove diversamente specificato, coincide con l'Interessato.</p>

  <h4>Interessato</h4>
  <p>La persona fisica cui si riferiscono i Dati Personali.</p>

  <h4>Responsabile del Trattamento (o Responsabile)</h4>
  <p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>

  <h4>Titolare del Trattamento (o Titolare)</h4>
  <p>La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di Cinemaniac. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di Cinemaniac.</p>

  <h4>Cinemaniac (o questa Applicazione)</h4>
  <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>

  <h4>Servizio</h4>
  <p>Il Servizio fornito da Cinemaniac così come definito nei relativi termini (se presenti) su questo sito/applicazione.</p>

  <h4>Unione Europea (o UE)</h4>
  <p>Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.</p>

  <h4>Cookies</h4>
  <p>Piccola porzione di dati conservata all'interno del dispositivo dell'Utente.</p>

  <h4>Riferimenti legali</h4>
  <p>La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679 (GDPR – General Data Protection Regulation).</p>
  <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente Cinemaniac.</p>
</div>

<footer>
  <small>Ultima modifica: 15 maggio 2020</small>
</footer>
`;
