import React from 'react';
import './Main.scss';
import icon from '../../resources/img/icon.png';
import social_fb from '../../resources/img/social_fb.png';
import social_ig from '../../resources/img/social_ig.png';
import flagIT from '../../resources/img/flag-it.png';
import flagUK from '../../resources/img/flag-uk.png';
import {Link} from "react-router-dom";
import {Stores} from "../../components/Stores/Stores";
import Icon from "@mdi/react";
import {
  mdiAccountMultipleOutline,
  mdiCurtains,
  mdiFolderOutline,
  mdiInformationOutline,
  mdiMovieCog,
  mdiMovieOpen,
  mdiMovieSearch,
  mdiStar,
  mdiTrayArrowDown
} from "@mdi/js";
import {strings} from "../../utils/localization";

export function Main() {

  React.useEffect(() => {
    document.title = 'Cinemaniac';
  }, []);

  return (
    <div id="main" className="container">
      <img id="icon" className="center" src={icon} alt="Cinemaniac icon" /><br/>

      <div id="header" className="center">
        <h1 className="cinemaniac-font">Cinemaniac</h1>
        <h3>{strings.tagline}</h3>
      </div>

      <div>
        <Stores />
      </div>

      <div id="store-info">
        <div id="store-downloads">
          <Icon path={mdiTrayArrowDown} title="Downloads" />
          <p dangerouslySetInnerHTML={{__html: strings.downloads || ''}}></p>
        </div>
        <div id="store-reviews">
          <div className="stars">
            <Icon path={mdiStar} title="Reviews" />
            <Icon path={mdiStar} title="Reviews" />
            <Icon path={mdiStar} title="Reviews" />
            <Icon path={mdiStar} title="Reviews" />
            <Icon path={mdiStar} title="Reviews" />
          </div>
          <Icon className="star-placeholder" path={mdiStar} title="Reviews" />
          <p dangerouslySetInnerHTML={{__html: strings.reviews || ''}}></p>
        </div>
      </div>

      <div id="app-info">
        <p>{strings.appInfo1}</p>

        <Icon path={mdiMovieOpen} title="Cinemaniac" size={2} />

        <div id="app-description">
          <p>{strings.appInfo2}</p>
          <p>{strings.appInfo3}</p>
        </div>

        <div id="app-features">
          <h3>{strings.features}</h3>

          <div className="app-features-container">
            <div>
              <Icon path={mdiMovieSearch} title={strings.features_content_title} size={1.2} />
              <h4>{strings.features_content_title}</h4>
              <p>{strings.features_content_desc}</p>
            </div>
            <div>
              <Icon path={mdiFolderOutline} title={strings.features_categories_title} size={1.2} />
              <h4>{strings.features_categories_title}</h4>
              <p>{strings.features_categories_desc}</p>
            </div>
            <div>
              <Icon path={mdiAccountMultipleOutline} title={strings.features_people_title} size={1.2} />
              <h4>{strings.features_people_title}</h4>
              <p>{strings.features_people_desc}</p>
            </div>
            <div>
              <Icon path={mdiInformationOutline} title={strings.features_more_info_title} size={1.2} />
              <h4>{strings.features_more_info_title}</h4>
              <p>{strings.features_more_info_desc}</p>
            </div>
            <div>
              <Icon path={mdiMovieCog} title={strings.features_customizations_title} size={1.2} />
              <h4>{strings.features_customizations_title}</h4>
              <p dangerouslySetInnerHTML={{__html: strings.features_customizations_desc || ''}}></p>
            </div>
            <div>
              <Icon path={mdiCurtains} title={strings.features_where_to_watch_title} size={1.2} />
              <h4>{strings.features_where_to_watch_title}</h4>
              <p>{strings.features_where_to_watch_desc}</p>
            </div>
          </div>
        </div>
      </div>

      <div id="social-title" className="center"><h3>{strings.followSocial}</h3></div>
      <div id="social" className="center">
        <a id="facebook" className="imaged" href="https://www.facebook.com/cinemaniac.moviestowatch/" target="_blank" rel="noreferrer">
          <img className="social-icon" alt="Facebook" title="Facebook" src={social_fb}/>
        </a>
        <a id="instagram" className="imaged" href="https://www.instagram.com/cinemaniac.app/" target="_blank" rel="noreferrer">
          <img className="social-icon" alt="Instagram" title="Instagram" src={social_ig}/>
        </a>
      </div>

      <div className="policies">
        <div id="privacy">
          <h3>{strings.privacyPolicy}</h3>
          <Link to="privacy/it/" id="flag-italy" className="flag-icon imaged">
            <img alt="Italiano" title="Italiano" src={flagIT}/>
          </Link>
          <Link to="privacy/en/" id="flag-uk" className="flag-icon imaged">
            <img alt="English" title="English" src={flagUK}/>
          </Link>
        </div>

        <div id="tos">
          <h3>{strings.tos}</h3>
          <Link to="tos/en/" id="flag-uk" className="flag-icon imaged">
            <img alt="English" title="English" src={flagUK}/>
          </Link>
        </div>
      </div>

    </div>
  );
}
