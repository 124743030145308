import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Main} from "./main/Main";
import {Privacy} from "./privacy/Privacy";
import {TopBar} from "../components/TopBar/TopBar";
import {createTheme, ThemeProvider} from '@mui/material';
import {Tos} from "./tos/Tos";
import {Contact} from "./contact/Contact";
import { Movie } from "./content/Movie";
import {Tv} from "./content/Tv";
import {Person} from "./person/Person";
import {NotFound} from "./notfound/NotFound";
import {Faq} from "./faq/Faq";

export function AppRouter() {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <TopBar />

        <Routes>
          <Route path="/" element={<Main />} />

          <Route path="/contact" element={<Contact />} />

          <Route path="/faq" element={<Faq />} />

          <Route path="/privacy" element={<Privacy />} />
          <Route path="/privacy/:path" element={<Privacy />} />

          <Route path="/tos" element={<Tos />} />
          <Route path="/tos/:path" element={<Tos />} />

          <Route path="/movie/:id" element={<Movie />} />
          <Route path="/tv/:id" element={<Tv />} />
          <Route path="/person/:id" element={<Person />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#FF6E40' },
    secondary: { main: '#00796B' },
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.6)',
      disabled: 'rgba(0,0,0,0.38)'
    }
  },
});
