import React from "react";
import {Container} from "@mui/material";
import {Stores} from "../../components/Stores/Stores";
import {strings} from "../../utils/localization";
import './Content.scss';

type State = {
  title?: string;
  subtitle?: string;
  overview?: string;
  imageURL?: string;
}

export function Content() {

  const [state, setState] = React.useState<State>({});

  React.useLayoutEffect(() => {
    const ogTitle = (document.querySelector('meta[name="og:title"]') as HTMLMetaElement)?.content; // __META_OG_TITLE__
    const cSubtitle = (document.querySelector('meta[name="c:subtitle"]') as HTMLMetaElement)?.content; // __META_C_SUBTITLE__
    const ogImage = (document.querySelector('meta[name="og:image"]') as HTMLMetaElement)?.content; // __META_OG_IMAGE__
    const ogDescription = (document.querySelector('meta[name="og:description"]') as HTMLMetaElement)?.content; // __META_OG_DESCRIPTION__

    setState({
      title:    ogTitle       !==  '__META_OG_TITLE__'       ? ogTitle :       '',
      subtitle: cSubtitle     !==  '__META_C_SUBTITLE__'     ? cSubtitle :     '',
      imageURL: ogImage       !==  '__META_OG_IMAGE__'       ? ogImage :       '',
      overview: ogDescription !==  '__META_OG_DESCRIPTION__' ? ogDescription : '',
    });
  }, []);

  React.useEffect(() => {
    if (state.title)
      document.title = `${state.title} – Cinemaniac`;
    else
      document.title = 'Cinemaniac';
  }, [state.title]);

  return (
    <div className="c-content">
      {state.imageURL && state.imageURL !== '' && (
        <div className="backdrop-wrapper">
          <img className="blurred-bd" src={state.imageURL} alt="Backdrop" />
          <Container className="backdrop-container" maxWidth="md">
            <img className="placeholder" src={state.imageURL} alt="Backdrop" />
            <img className="backdrop" src={state.imageURL} alt="Backdrop" />
          </Container>
        </div>
      )}
      <div className="content-info">
        <Container maxWidth="md">
          <h2>{state.title ? state.title : <em>No title</em>}</h2>
          <p className="subtitle">{state.subtitle}</p>
          <p className="overview">{state.overview}</p>
        </Container>
      </div>
      <div className="stores-bg">
        <h2 dangerouslySetInnerHTML={{__html: strings.viewMoreOnApp || ''}}></h2>
        <Stores />
      </div>
    </div>
  );
}
