import React from "react";
import './TopBar.scss';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar
} from "@mui/material";
import Icon from "@mdi/react";
import {
  mdiEmailEditOutline,
  mdiFileSign,
  mdiFrequentlyAskedQuestions,
  mdiMenu,
  mdiMovieOpen,
  mdiShieldLockOutline
} from "@mdi/js";
import {strings} from "../../utils/localization";

export const TopBar = React.memo(TopBarComponent);

const pages = [
  { label: 'FAQ', href: '/faq', icon: mdiFrequentlyAskedQuestions },
  { label: strings.contact, href: '/contact', icon: mdiEmailEditOutline },
  { label: strings.privacyPolicy, href: '/privacy', icon: mdiShieldLockOutline },
  { label: strings.tos, href: '/tos', icon: mdiFileSign },
];

function TopBarComponent() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  if (location.pathname === '/')
    return null;

  return (
    <AppBar className="c-topbar" position="static">
      <Toolbar>
        <Container maxWidth="md">
          <div id="topbar-icon" />
          <Link to="/" className="topbar-logo">Cinemaniac</Link>

          <div style={{ flex: 1 }} />

          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(true)}
            sx={{ display: { xs: 'flex', md: 'none' } }}
          >
            <Icon path={mdiMenu} size={1.2} color="#FFFFFF" />
          </IconButton>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => (
              <Button
                key={`menu-item-${index}`}
                onClick={() => navigate({ pathname: page.href })}
                sx={{ px: 2, color: 'white', display: 'block' }}
              >
                {page.label}
              </Button>
            ))}
          </Box>

          <Drawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            anchor="top"
            sx={{ display: { xs: 'flex', md: 'none' } }}
          >
            <List>
              <ListItem key={`drawer-item-home`} onClick={() => {
                setIsDrawerOpen(false);
                navigate({pathname: '/'});
              }}>
                <ListItemIcon>
                  <Icon path={mdiMovieOpen} size={1} />
                </ListItemIcon>
                <ListItemText primary={strings.homepage} />
              </ListItem>
            </List>
            <Divider />
            <List>
              {pages.map((page, index) => (
                <ListItem key={`drawer-item-${index}`} onClick={() => {
                  setIsDrawerOpen(false);
                  navigate({pathname: page.href});
                }}>
                  <ListItemIcon>
                    <Icon path={page.icon} size={1} />
                  </ListItemIcon>
                  <ListItemText primary={page.label} />
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Container>

      </Toolbar>
    </AppBar>
  )
}
