/* eslint no-useless-concat: 0 */

export function setEmail(withLabel = false) {
  const aa = "a";
  const oo = "o";
  const c  = "c";
  const nn = "n";
  const p  = "pp";

  const e = "em" + aa + "il";

  const a = "su" + p + oo + "rt" + "@" + c + "i" + nn + "em" + aa + nn + "i" + aa + c + "." + aa + p;
  const s = "?subject=Feedback%20about%20Cinemaniac";

  setTimeout(() => {
    Array.from(document.getElementsByClassName('owner-email')).forEach(element => {
      if (element.innerHTML !== '')
        element.innerHTML = '<' + aa + ' hr' + 'ef=' + '\'m' + 'ai' + 'lt' + 'o:' + a + s + '\'>' + element.innerHTML + '</a>';
      else {
        element.innerHTML = withLabel
          ? '(' + e + ': <' + aa + ' hr' + 'ef=' + '\'m' + 'ai' + 'lt' + 'o:' + a + s + '\'>' + a + '</a>)'
          : '<' + aa + ' hr' + 'ef=' + '\'m' + 'ai' + 'lt' + 'o:' + a + s + '\'>' + a + '</a>';
      }
    });
  }, 100);
}
