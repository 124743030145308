import React from "react";
import "./Person.scss";
import {Container} from "@mui/material";
import {strings} from "../../utils/localization";
import {Stores} from "../../components/Stores/Stores";

type State = {
  title?: string;
  subtitle?: string;
  overview?: string;
  imageURL?: string;
  movie_cast?: number;
  movie_crew?: number;
  tv_cast?: number;
  tv_crew?: number;
}

export function Person() {

  const [state, setState] = React.useState<State>({});

  React.useLayoutEffect(() => {
    const ogTitle = (document.querySelector('meta[name="og:title"]') as HTMLMetaElement)?.content; // __META_OG_TITLE__
    const cSubtitle = (document.querySelector('meta[name="c:subtitle"]') as HTMLMetaElement)?.content; // __META_C_SUBTITLE__
    const ogImage = (document.querySelector('meta[name="og:image"]') as HTMLMetaElement)?.content; // __META_OG_IMAGE__
    const ogDescription = (document.querySelector('meta[name="og:description"]') as HTMLMetaElement)?.content; // __META_OG_DESCRIPTION__
    const data = (document.querySelector('meta[name="c:data"]') as HTMLMetaElement)?.content; // __META_C_DATA__

    setState({
      title:    ogTitle       !==  '__META_OG_TITLE__'       ? ogTitle :       '',
      subtitle: cSubtitle     !==  '__META_C_SUBTITLE__'     ? cSubtitle :     '',
      imageURL: ogImage       !==  '__META_OG_IMAGE__'       ? ogImage :       '',
      overview: ogDescription !==  '__META_OG_DESCRIPTION__' ? ogDescription : '',
      ...(data && data !== '' && data !== '__META_C_DATA__' && JSON.parse(decodeURIComponent(data))),
    });
  }, []);

  React.useEffect(() => {
    if (state.title)
      document.title = `${state.title} – Cinemaniac`;
    else
      document.title = 'Cinemaniac';
  }, [state.title]);

  return (
    <div className="c-person">
      <div className="content-info">
        <Container maxWidth="md">
          <div className="row">
            {state.imageURL && state.imageURL !== '' && <img className="backdrop" src={state.imageURL} alt="Profile"/>}
            <div className="info">
              <h2>{state.title ? state.title : <em>No name</em>}</h2>
              {state.subtitle && state.subtitle !== '' && (<p className="subtitle">{state.subtitle}</p>)}
              {state.overview && state.overview !== 'View more with Cinemaniac app!' && (<p className="overview">{state.overview}</p>)}
              <ul>
                {state.movie_cast && state.movie_cast > 0 && (<li>{strings.formatString(strings.cast_movies || '', {value: state.movie_cast})}</li>)}
                {state.movie_crew && state.movie_crew > 0 && (<li>{strings.formatString(strings.directed_movies || '', {value: state.movie_crew})}</li>)}
                {state.tv_cast && state.tv_cast > 0 && (<li>{strings.formatString(strings.cast_shows || '', {value: state.tv_cast})}</li>)}
                {state.tv_crew && state.tv_crew > 0 && (<li>{strings.formatString(strings.directed_shows || '', {value: state.tv_crew})}</li>)}
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <div className="stores-bg">
        <h2 dangerouslySetInnerHTML={{__html: strings.viewMoreOnApp || ''}}></h2>
        <Stores />
      </div>
    </div>
  );
}
