import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    tagline: 'The definitive app for cinema lovers',

    downloads: `More than <span class="highlight">1 Million</span> of downloads`,
    reviews: `More than <span class="highlight">13 Thousands</span> of reviews`,

    appInfo1: 'Cinemaniac is the perfect app for all movie and TV show lovers!',
    appInfo2: 'With this app, you can track all the movies and TV shows you want to see, get detailed information about the content and various actors and directors, create custom categories, discover new films based on your interests, and vote on the movies and TV shows you\'ve seen.',
    appInfo3: 'Never miss an interesting plot or a favorite actor again: download Cinemaniac now and start discovering the world of movies and TV shows like never before!',

    features: 'Main features',
    features_content_title: 'Millions of contents',
    features_content_desc: 'Browse and search for your favorite contents among millions of movies and TV shows, filter by genre and year, view the most viewed contents and those currently in theaters or streaming',
    features_categories_title: 'Custom lists',
    features_categories_desc: 'Create an unlimited number of custom lists to categorize your movies and TV shows according to your preferred criteria, it\'s the best way to have a comfortable personal library',
    features_people_title: 'Actors and directors',
    features_people_desc: 'Keep an eye on your favorite actors and directors and view their movies and TV shows',
    features_more_info_title: 'More info',
    features_more_info_desc: 'Read the plots and watch the trailers of the movies and TV shows that seem interesting, you have all the information about the contents at hand',
    features_customizations_title: 'Endless customizations',
    features_customizations_desc: `This app is designed to give you the possibility to customize every aspect of it, according to your needs.<br />The user experience of Cinemaniac always comes first!`,
    features_where_to_watch_title: 'Where to watch',
    features_where_to_watch_desc: 'Are you interested in knowing which are the streaming platforms and services where you can watch movies and TV shows? With Cinemaniac you can know it in a blink of an eye!',

    downloadApp: `Download <span class="cinemaniac-font">Cinemaniac</span> app!`,
    viewMoreOnApp: `View more on <span class="cinemaniac-font">Cinemaniac</span> app!`,
    followSocial: 'Follow Cinemaniac',
    privacyPolicy: 'Privacy',
    tos: 'Terms of Use',
    homepage: 'Home page',
    contact: 'Contact me',
    contact_title: 'Support about Cinemaniac',
    contact_p1: 'Would you like to leave a feedback to let me know what you think?',
    contact_p2: 'Have you experienced any kind of issue using my app?',
    contact_p3: 'Do you have suggestions to help me improve your experience?',
    contact_p4: `Send an email to <span class="owner-email" />`,

    cast_movies: 'Appeared in {value} movies',
    directed_movies: 'Directed {value} movies',
    cast_shows: 'Appeared in {value} TV shows',
    directed_shows: 'Directed {value} TV shows',

    faq_title: 'Frequently Asked Questions',
    faq_table_of_contents: 'Table of contents',
    faq_description: `This FAQ section was created to provide answers to the most common questions about Cinemaniac and to offer immediate support. If you have any doubts or uncertainties about using the application, we recommend consulting the FAQ section as a starting point to find the answers you need. If you cannot find the answer you are looking for or have more complex questions, we recommend <span class="owner-email">contacting the author</span> for further assistance.`,

    faq_tmdb_data_title: 'Can I edit or add data in Cinemaniac?',
    faq_tmdb_data_description: `The data about movies and TV series are taken mainly from <a href="https://www.themoviedb.org/" target="_blank">The Movie Database</a> (TMDb), a website that provides a vast collection of information about the world of cinema.<br />In addition, TMDb is a community-driven platform, which means that registered users can contribute to the collection of information by adding new movies, TV series, and characters to the database, modifying existing entries, and providing reviews and ratings.<br /><br />If you are interested in contributing, you can register to TMDb to provide updated data and help make the database more complete.<br /><br />Changes are usually reflected in the app within a few hours, if not immediately.`,

    faq_login_title: 'Why should I log in to Cinemaniac?',
    faq_login_description: `Logging in to Cinemaniac is highly recommended for two reasons: to protect your personal data on movies and TV series, and to keep them synchronized across all your devices.<br /><br />When you log in, automatic synchronization is activated, so your data is securely stored and accessible only to you, on all your devices. Also, thanks to logging in, you do not run the risk of losing important information in case of cellphone failure, accidental device formatting, or other unforeseen events.<br /><br />Whatever happens, you just need to log in again to retrieve your data. In other words, logging in to Cinemaniac provides you with the security and peace of mind to enjoy your passion for movies and television to the fullest.`,

    faq_android_restore_title: 'I cannot restore the PRO purchase on Android',
    faq_android_restore_description: `Usually this happens when there are multiple Google accounts logged in the same device. Here's how to resolve the issue:<br /><ol><li>Perform a manual backup by navigating to the menu → settings → backup</li><li>Uninstall Cinemaniac</li><li>Open the Play Store and in the top right corner, select the same Google account you used to make the purchase</li><li>Find Cinemaniac and install it again</li><li>Open Cinemaniac, your PRO account should now be automatically restored</li></ol>`,

    faq_content_rights_title: 'Why can\'t I watch movies or TV shows on Cinemaniac?',
    faq_content_rights_description: `Cinemaniac is an application designed to help movie lovers manage their movie and TV series library, but it does not have the necessary rights to directly provide content. This means that you cannot use the app to watch movies or TV shows in streaming.<br /><br />However, Cinemaniac offers many other useful features for movie enthusiasts, so they can have an excellent ally at their fingertips to manage their movie and TV series library.`,

    faq_delete_data_title: 'Can I delete my data from Cinemaniac?',
    faq_delete_data_description: `Yes, you have the right to request the complete deletion of your data, in accordance with the <a href="https://cinemaniac.app/privacy">privacy policy</a> following the General Data Protection Regulation (GDPR).<br/><br/>To proceed with the deletion of your account, follow these steps:<br /><ol><li>Open the Cinemaniac app</li><li>Go to menu → info & feedback</li><li>Select 'Delete your account'</li><li>Follow the provided instructions to confirm your account deletion request</li></ol><br />Once this process is completed, all your personal data will be removed from your device and Cinemaniac's server.<br/><br/>Please note that this action is irreversible, and it will not be possible to recover your data after the account deletion.`,

    faq_end_section_title: 'Didn\'t find the answer to your question?',
    faq_end_section_description: `Thank you for browsing the 'Frequently Asked Questions' section. If you need further support and information, do not hesitate to contact the developer directly.<br /><br />You can do so by sending an email to <span class="owner-email"></span> or by following Cinemaniac on social media.`,

  },
  it: {
    tagline: 'L\'app definitiva per gli amanti del cinema',

    downloads: `Più di <span class="highlight">un milione</span> di download`,
    reviews: `Più di <span class="highlight">13 mila</span> recensioni`,

    appInfo1: 'Cinemaniac è l\'app perfetta per tutti gli amanti dei film e delle serie TV!',
    appInfo2: 'Con questa app, puoi tenere traccia di tutti i film e le serie TV che vuoi vedere, ottenere informazioni dettagliate sui contenuti e su vari attori e registi, creare categorie personalizzate, scoprire nuovi film in base ai tuoi interessi e votare sui film e le serie TV che hai visto.',
    appInfo3: 'Non perdere mai più una trama interessante o un attore preferito: scarica Cinemaniac ora e inizia a scoprire il mondo dei film e delle serie TV come mai prima d\'ora!',

    features: 'Funzionalità principali',
    features_content_title: 'Milioni di contenuti',
    features_content_desc: 'Sfoglia e cerca i tuoi contenuti preferiti tra milioni di film e serie TV, sfrutta i filtri per genere e per anno, guarda i contenuti più visti e quelli attualmente al cinema o in streaming',
    features_categories_title: 'Liste personalizzate',
    features_categories_desc: 'Crea un numero illimitato di liste personalizzate per categorizzare i tuoi film e serie TV secondo i criteri che preferisci, è il modo migliore per avere una comodissima libreria personale',
    features_people_title: 'Attori e registi',
    features_people_desc: 'Tieni d\'occhio le schede dei tuoi attori e registi preferiti e visualizza i loro film e serie TV',
    features_more_info_title: 'Maggiori informazioni',
    features_more_info_desc: 'Leggi le trame e guarda i trailer dei film e delle serie TV che sembrano interessanti, hai tutte le informazioni sui contenuti a portata di mano',
    features_customizations_title: 'Infinite possibilità',
    features_customizations_desc: `Questa app è stata studiata per lasciare a te la possibilità di personalizzarla a 360°, secondo le tue esigenze.<br />L'esperienza utente di Cinemaniac è sempre al primo posto!`,
    features_where_to_watch_title: 'Dove guardare',
    features_where_to_watch_desc: 'Ti interessa sapere quali sono le piattaforme streaming e i servizi dove puoi guardare i film e le serie TV? Con Cinemaniac puoi saperlo in un batter d\'occhio!',

    downloadApp: `Scarica <span class="cinemaniac-font">Cinemaniac</span>!`,
    viewMoreOnApp: `Scopri di più su <span class="cinemaniac-font">Cinemaniac</span>!`,
    followSocial: 'Segui Cinemaniac',
    privacyPolicy: 'Privacy',
    tos: 'Termini d\'uso',

    homepage: 'Pagina principale',
    contact: 'Contattami',
    contact_title: 'Supporto su Cinemaniac',
    contact_p1: 'Vorresti lasciare un feedback per farmi sapere cosa ne pensi?',
    contact_p2: 'Hai riscontrato qualche problema utilizzando Cinemaniac?',
    contact_p3: 'Hai suggerimenti per migliorare la tua esperienza?',
    contact_p4: `Invia un'email a <span class="owner-email" />`,

    cast_movies: 'Ha recitato in {value} film',
    directed_movies: 'Ha diretto {value} film',
    cast_shows: 'Ha recitato in {value} serie TV',
    directed_shows: 'Ha diretto {value} serie TV',

    faq_title: 'Domande frequenti',
    faq_table_of_contents: 'Indice',
    faq_description: `Questa sezione FAQ è stata creata per fornire risposte alle domande più frequenti su Cinemaniac e per offrire supporto immediato. In caso di dubbi o incertezze sull'utilizzo dell'applicazione, si consiglia di consultare la sezione FAQ come punto di partenza per trovare le risposte di cui si ha bisogno. Se non si trova la risposta cercata o se si hanno domande più complesse, si consiglia di <span class="owner-email">contattare l'autore</span> per ulteriore assistenza.`,

    faq_tmdb_data_title: 'Posso modificare o aggiungere i dati presenti in Cinemaniac?',
    faq_tmdb_data_description: `I dati sui film e le serie TV sono presi principalmente da <a href="https://www.themoviedb.org/" target="_blank">The Movie Database</a> (TMDb), un sito web che fornisce una vasta raccolta di informazioni sul mondo del cinema.<br />Inoltre, TMDb è una community-driven platform, il che significa che gli utenti registrati possono contribuire alla raccolta di informazioni, aggiungendo nuovi film, serie TV e personaggi al database, modificando schede esistenti e fornendo recensioni e valutazioni.<br /><br />Se sei interessato a contribuire, potresti registrarti su TMDb per poter fornire dati aggiornati e aiutare a rendere il database sempre più completo.<br /><br />Le modifiche solitamente si riflettono nell'app nel giro di qualche ora, se non immediatamente.`,

    faq_login_title: 'Perché effettuare il login in Cinemaniac?',
    faq_login_description: `La login in Cinemaniac è estremamente consigliata per due motivi: per proteggere i tuoi dati personali sui film e sulle serie TV, e per mantenerli sincronizzati su tutti i tuoi dispositivi.<br /><br />Quando effettui l'accesso si attiva la sincronizzazione automatica, in questo modo i tuoi dati vengono memorizzati in modo sicuro e accessibili solo a te, su tutti i tuoi dispositivi. Inoltre, grazie alla login non hai il rischio di perdere informazioni importanti in caso di guasti al tuo cellulare, formattazione accidentale del dispositivo o altri imprevisti.<br /><br />Qualsiasi cosa succeda, ti basterà rieffettuare l'accesso per riavere i tuoi dati. In altre parole, l'accesso su Cinemaniac ti offre la sicurezza e la tranquillità per goderti al meglio la tua passione per il cinema e la televisione.`,

    faq_android_restore_title: 'Non riesco a ripristinare l\'account PRO su Android',
    faq_android_restore_description: `Solitamente ciò accade quando ci sono più account Google registrati sullo stesso dispositivo. Ecco come risolvere il problema:<br /><ol><li>Esegui un backup manuale navigando nel menu → impostazioni → backup</li><li>Disinstalla Cinemaniac</li><li>Apri il Play Store e nell'angolo in alto a destra seleziona lo stesso account Google che hai usato per effettuare l'acquisto</li><li>Cerca Cinemaniac ed installalo nuovamente</li><li>Apri Cinemaniac, ora il tuo account PRO dovrebbe essere ripristinato automaticamente</li></ol>`,

    faq_content_rights_title: 'Perché non posso guardare film o serie TV su Cinemaniac?',
    faq_content_rights_description: `Cinemaniac è un'applicazione progettata per aiutare i cinefili a gestire la propria libreria di film e serie TV, ma non dispone dei diritti necessari per fornire direttamente i contenuti. Ciò significa che non è possibile utilizzare l'app per guardare film o serie TV in streaming.<br /><br />Tuttavia, Cinemaniac offre molte altre funzionalità utili per gli appassionati di cinema, in modo che possano avere comodamente a portata di mano un ottimo alleato per gestire la propria libreria dei film e delle serie TV.`,

    faq_delete_data_title: 'Posso eliminare i miei dati da Cinemaniac?',
    faq_delete_data_description: `Sì, hai il diritto di richiedere la completa eliminazione dei tuoi dati, in base alle <a href="https://cinemaniac.app/privacy">politiche sulla privacy</a> in conformità con il Regolamento Generale sulla Protezione dei Dati (GDPR).<br/><br/>Per procedere con l'eliminazione del tuo account, segui questi passaggi:<br /><ol><li>Apri l'app Cinemaniac</li><li>Vai in menu → info e feedback</li><li>Seleziona "Elimina il tuo account"</li><li>Segui le istruzioni fornite per confermare la tua richiesta di eliminazione dell'account</li></ol><br />Una volta completata questa procedura, tutti i tuoi dati personali saranno rimossi dal tuo dispositivo e dal server di Cinemaniac.<br/><br/>Tieni presente che questa azione è irreversibile e non sarà possibile ripristinare i tuoi dati dopo l'eliminazione dell'account.`,

    faq_end_section_title: 'Non hai trovato la risposta alla tua domanda?',
    faq_end_section_description: `Grazie per aver sfogliato la sezione "Domande frequenti", se hai bisogno di ulteriore supporto e informazioni non esitare a contattare direttamente lo sviluppatore.<br /><br />Puoi farlo inviando un'email all'indirizzo <span class="owner-email"></span> o seguendo Cinemaniac sui social media.`,

  },
  es: {
    tagline: 'La aplicación definitiva para los amantes del cine',

    downloads: `Más de <span class="highlight">1 millón</span> de descargas`,
    reviews: `Más de <span class="highlight">13 mil</span> reseñas`,

    appInfo1: 'Cinemaniac es la aplicación perfecta para todos los amantes de las películas y programas de televisión!',
    appInfo2: 'Con esta aplicación, puedes hacer un seguimiento de todas las películas y programas de televisión que deseas ver, obtener información detallada sobre el contenido y varios actores y directores, crear categorías personalizadas, descubrir nuevas películas basadas en tus intereses y votar sobre las películas y programas de televisión que has visto.',
    appInfo3: 'Nunca más pierdas un argumento interesante o un actor favorito: descarga Cinemaniac ahora y comienza a descubrir el mundo de las películas y programas de televisión como nunca antes!',

    features: 'Principales características',
    features_content_title: 'Millones de contenidos',
    features_content_desc: 'Explora y busca tus contenidos favoritos entre millones de películas y programas de televisión, filtra por género y año, mira los contenidos más vistos y los que están actualmente en cines o transmisión',
    features_categories_title: 'Listas personalizadas',
    features_categories_desc: 'Crea un número ilimitado de listas personalizadas para categorizar tus películas y programas de televisión de acuerdo con tus criterios preferidos, es la mejor manera de tener una biblioteca personal cómoda',
    features_people_title: 'Actores y directores',
    features_people_desc: 'Mantén un ojo en tus actores y directores favoritos y mira sus películas y programas de televisión',
    features_more_info_title: 'Más información',
    features_more_info_desc: 'Lee los argumentos y mira los trailers de las películas y programas de televisión que parecen interesantes, tienes toda la información sobre los contenidos a mano',
    features_customizations_title: 'Personalizaciones interminables',
    features_customizations_desc: `Esta aplicación está diseñada para darte la posibilidad de personalizar cada aspecto de ella, según tus necesidades.<br />La experiencia de usuario de Cinemaniac siempre es lo primero!`,
    features_where_to_watch_title: 'Dónde ver',
    features_where_to_watch_desc: '¿Estás interesado en conocer cuáles son las plataformas y servicios de transmisión donde puedes ver películas y programas de televisión? ¡Con Cinemaniac puedes saberlo en un abrir y cerrar de ojos!',

    downloadApp: `Descargar la aplicación <span class="cinemaniac-font">Cinemaniac</span>!`,
    viewMoreOnApp: `Ver más en la aplicación <span class="cinemaniac-font">Cinemaniac</span>!`,
    followSocial: 'Sigue a Cinemaniac',
    privacyPolicy: 'Política de privacidad',
    tos: 'Términos de uso',
    homepage: 'Página principal',
    contact: 'Contáctame',
    contact_title: 'Soporte sobre Cinemaniac',
    contact_p1: '¿Te gustaría dejar un comentario para que yo sepa lo que piensas?',
    contact_p2: '¿Has experimentado algún tipo de problema al usar mi aplicación?',
    contact_p3: '¿Tienes sugerencias para ayudarme a mejorar tu experiencia?',
    contact_p4: `Envíame un correo electrónico a <span class="owner-email" />`,

    cast_movies: 'Apareció en {value} películas',
    directed_movies: 'Dirigió {value} películas',
    cast_shows: 'Apareció en {value} programas de televisión',
    directed_shows: 'Dirigió {value} programas de televisión',
  },
  pt: {
    tagline: 'A aplicação definitiva para amantes de cinema',

    downloads: `Mais de <span class="highlight">1 Milhão</span> de downloads`,
    reviews: `Mais de <span class="highlight">13 Mil</span> de avaliações`,

    appInfo1: 'O Cinemaniac é a aplicação perfeita para todos os amantes de filmes e séries de TV!',
    appInfo2: 'Com esta aplicação, pode acompanhar todos os filmes e séries de TV que quer ver, obter informações detalhadas sobre o conteúdo e vários atores e realizadores, criar categorias personalizadas, descobrir novos filmes com base nos seus interesses e votar nos filmes e séries de TV que já viu.',
    appInfo3: 'Nunca perca um enredo interessante ou um ator favorito de novo: baixe o Cinemaniac agora e comece a descobrir o mundo dos filmes e séries de TV como nunca antes!',

    features: 'Principais recursos',
    features_content_title: 'Milhões de conteúdos',
    features_content_desc: 'Navegue e pesquise pelo seu conteúdo favorito entre milhões de filmes e séries de TV, filtre por gênero e ano, veja os conteúdos mais vistos e aqueles atualmente em exibição nos cinemas ou em streaming',
    features_categories_title: 'Listas personalizadas',
    features_categories_desc: 'Crie uma quantidade ilimitada de listas personalizadas para categorizar seus filmes e séries de TV de acordo com seus critérios preferidos, é a melhor maneira de ter uma biblioteca pessoal confortável',
    features_people_title: 'Atores e diretores',
    features_people_desc: 'Mantenha o olho nos seus atores e diretores favoritos e veja seus filmes e séries de TV',
    features_more_info_title: 'Mais informações',
    features_more_info_desc: 'Leia os enredos e assista aos trailers dos filmes e séries de TV que parecem interessantes, você tem todas as informações sobre os conteúdos à mão',
    features_customizations_title: 'Personalizações intermináveis',
    features_customizations_desc: `Esta aplicação está projetada para lhe dar a possibilidade de personalizar todos os aspectos dela, de acordo com suas necessidades.<br />A experiência do usuário do Cinemaniac sempre vem primeiro!`,
    features_where_to_watch_title: 'Onde assistir',
    features_where_to_watch_desc: 'Você está interessado em saber quais são as plataformas de streaming e serviços onde você pode assistir a filmes e séries de TV? Com o Cinemaniac, você pode saber em um piscar de olhos!',

    downloadApp: `Baixe o aplicativo <span class="cinemaniac-font">Cinemaniac</span>!`,
    viewMoreOnApp: `Veja mais no aplicativo <span class="cinemaniac-font">Cinemaniac</span>!`,
    followSocial: 'Siga o Cinemaniac',
    privacyPolicy: 'Política de privacidade',
    tos: 'Termos de uso',
    homepage: 'Página inicial',
    contact: 'Entre em contato',
    contact_title: 'Suporte sobre o Cinemaniac',
    contact_p1: 'Você gostaria de deixar um feedback para me dizer o que você pensa?',
    contact_p2: 'Você experimentou algum tipo de problema usando meu aplicativo?',
    contact_p3: 'Você tem sugestões para me ajudar a melhorar sua experiência?',
    contact_p4: `Envie-me um email para <span class="owner-email" />`,

    cast_movies: 'Apareceu em {value} filmes',
    directed_movies: 'Dirigiu {value} filmes',
    cast_shows: 'Apareceu em {value} programas de TV',
    directed_shows: 'Dirigiu {value} programas de TV',
  },
  fr: {
    tagline: 'L\'application définitive pour les amateurs de cinéma',

    downloads: `Plus de <span class="highlight">1 million</span> de téléchargements`,
    reviews: `Plus de <span class="highlight">13 000</span> avis`,

    appInfo1: 'Cinemaniac est l\'application parfaite pour tous les amateurs de films et de séries télévisées!',
    appInfo2: 'Avec cette application, vous pouvez suivre tous les films et séries télévisées que vous voulez voir, obtenir des informations détaillées sur le contenu et les différents acteurs et réalisateurs, créer des catégories personnalisées, découvrir de nouveaux films en fonction de vos centres d\'intérêt et voter sur les films et séries télévisées que vous avez vus.',
    appInfo3: 'Ne manquez plus jamais un scénario intéressant ou un acteur préféré: téléchargez Cinemaniac dès maintenant et découvrez le monde des films et des séries télévisées comme jamais auparavant!',

    features: 'Fonctionnalités principales',
    features_content_title: 'Des millions de contenus',
    features_content_desc: 'Parcourez et recherchez vos contenus préférés parmi des millions de films et de séries télévisées, filtrez par genre et année, visualisez les contenus les plus vus et ceux actuellement en salles ou en streaming',
    features_categories_title: 'Listes personnalisées',
    features_categories_desc: 'Créez un nombre illimité de listes personnalisées pour catégoriser vos films et séries télévisées selon vos critères préférés, c\'est la meilleure façon d\'avoir une bibliothèque personnelle confortable',
    features_people_title: 'Acteurs et réalisateurs',
    features_people_desc: 'Suivez de près vos acteurs et réalisateurs préférés et consultez leurs films et séries télévisées',
    features_more_info_title: 'Plus d\'informations',
    features_more_info_desc: 'Lisez les scénarios et regardez les bandes-annonces des films et séries télévisées qui vous intéressent, vous avez toutes les informations sur les contenus à portée de main',
    features_customizations_title: 'Personnalisations illimitées',
    features_customizations_desc: `Cette application est conçue pour vous donner la possibilité de personnaliser tous les aspects de l'application en fonction de vos besoins.<br />L'expérience utilisateur de Cinemaniac est toujours prioritaire!`,
    features_where_to_watch_title: 'Où regarder',
    features_where_to_watch_desc: 'Vous êtes intéressé de savoir sur quelles plateformes de streaming et services vous pouvez regarder des films et des séries télévisées? Avec Cinemaniac, vous pouvez le savoir en un clin d\'œil!',

    downloadApp: `Téléchargez l'application <span class="cinemaniac-font">Cinemaniac</span>!`,
    viewMoreOnApp: `Voir plus sur l'application <span class="cinemaniac-font">Cinemaniac</span>!`,
    followSocial: 'Suivez Cinemaniac',
    privacyPolicy: 'Politique de confidentialité',
    tos: 'Conditions d\'utilisation',
    homepage: 'Page d\'accueil',
    contact: 'Contactez-moi',
    contact_title: 'Support pour Cinemaniac',
    contact_p1: 'Voulez-vous laisser un commentaire pour me faire savoir ce que vous pensez?',
    contact_p2: 'Avez-vous rencontré un problème en utilisant mon application?',
    contact_p3: 'Avez-vous des suggestions pour m\'aider à améliorer votre expérience?',
    contact_p4: `Envoyez-moi un email à <span class="owner-email" />`,

    cast_movies: 'Apparu dans {value} films',
    directed_movies: 'A dirigé {value} films',
    cast_shows: 'Apparu dans {value} séries télévisées',
    directed_shows: 'A dirigé {value} séries télévisées',
  }
});