import React from "react";
import './Faq.scss';
import {Container} from "@mui/material";
import {strings} from "../../utils/localization";
import {Stores} from "../../components/Stores/Stores";
import {HashLink} from 'react-router-hash-link';
import {setEmail} from '../../utils/email';

export function Faq() {

  React.useEffect(() => {
    document.title = `${strings.faq_title} – Cinemaniac`;
    setEmail();
  }, []);

  return (
    <div>
      <div className="c-faq">
        <Container maxWidth="md">
          <h1>{strings.faq_title}</h1>
          <p dangerouslySetInnerHTML={{__html: strings.faq_description || ''}}></p>

          <h2>{strings.faq_table_of_contents}</h2>

          <ul id="summary">
            <li><HashLink smooth to="/faq#tmdb-data">{strings.faq_tmdb_data_title}</HashLink></li>
            <li><HashLink smooth to="/faq#login">{strings.faq_login_title}</HashLink></li>
            <li><HashLink smooth to="/faq#pro-restore-android">{strings.faq_android_restore_title}</HashLink></li>
            <li><HashLink smooth to="/faq#account-deletion">{strings.faq_delete_data_title}</HashLink></li>
            <li><HashLink smooth to="/faq#content-rights">{strings.faq_content_rights_title}</HashLink></li>
          </ul>

          <h3 id="tmdb-data">{strings.faq_tmdb_data_title}</h3>
          <p dangerouslySetInnerHTML={{__html: strings.faq_tmdb_data_description || ''}}></p>

          <h3 id="login">{strings.faq_login_title}</h3>
          <p dangerouslySetInnerHTML={{__html: strings.faq_login_description || ''}}></p>

          <h3 id="pro-restore-android">{strings.faq_android_restore_title}</h3>
          <p dangerouslySetInnerHTML={{__html: strings.faq_android_restore_description || ''}}></p>

          <h3 id="content-rights">{strings.faq_content_rights_title}</h3>
          <p dangerouslySetInnerHTML={{__html: strings.faq_content_rights_description || ''}}></p>

          <h3 id="account-deletion">{strings.faq_delete_data_title}</h3>
          <p dangerouslySetInnerHTML={{__html: strings.faq_delete_data_description || ''}}></p>

          <h3>{strings.faq_end_section_title}</h3>
          <p dangerouslySetInnerHTML={{__html: strings.faq_end_section_description || ''}}></p>
        </Container>
      </div>
      <div className="stores-bg">
        <h2 dangerouslySetInnerHTML={{__html: strings.downloadApp || ''}}></h2>
        <Stores />
      </div>
    </div>
  );
}