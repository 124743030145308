
module.exports = `
<header>
  <h1>Cinemaniac's Terms of Use</h1>
</header>

<div class="full-policy">
  <h3>Author</h3>
  <p>Antonio Papalillo <span class="secondary owner-email"></span></p>

  <h3>Scope of Application and Use</h3>

  <p>These Terms of Use (the "Agreement") form a legal agreement between you, the end user (referenced herein as "you") and Cinemaniac that applies each time you use or access any of the offering on:</p>
  <ol>
    <li>The website <a href="https://cinemaniac.app/">https://cinemaniac.app/</a> (hereinafter "Website")</li>
    <li>The Cinemaniac app for Android devices (mainly distributed via Google Play Store: <a href="https://play.google.com/store/apps/details?id=it.papalillo.moviestowatch" target="_blank">https://play.google.com/store/apps/details?id=it.papalillo.moviestowatch</a>)</li>
    <li>The Cinemaniac app for iOS devices (mainly distributed via App Store: <a href="https://apps.apple.com/app/cinemaniac/id1450924201" target="_blank">https://apps.apple.com/app/cinemaniac/id1450924201</a>)</li>
  </ol>
  
  <p>All of the above are properties of Antonio Papalillo (hereinafter "I", "me" or "author"), each individually and all collectively as "Cinemaniac".</p>
  
  <p>I reserve the right at any time to:</p>
  
  <ol>
    <li>Change the terms and conditions of this Agreement</li>
    <li>Change any part of Cinemaniac, including eliminating or discontinuing any content or feature of Cinemaniac</li>
    <li>Change any fees or charges for use of Cinemaniac</li>
  </ol>
  
  <p>Cinemaniac’s service is intended for personal and non-commercial use only and only for users of adult age. Any user who is not yet an adult must have the permission of his/her parents or legal guardian, insofar as this is legally permissible, and the parents or legal guardian must have acknowledged these Terms of Use.</p>
  
  <p>Applications for mobile devices ("Cinemaniac apps" or "apps") are normally not provided by me directly, but by a third-party provider (hereinafter "App store"). I cannot guarantee that Cinemaniac will have the same functionality, design, and user experience on all mobile devices. Additionally, users should review the terms of use from their respective App store.</p>
  
  <p>Cinemaniac is provided "as is" and "as available" without any warranties, express or implied. I do not provide any guarantees or warranties, including warranties of merchantability, suitability for a specific purpose, availability, safety, ownership, or non-infringement of rights.</p>
  
  <p>Cinemaniac may contain inaccuracies, errors, bugs, or interruptions, and I do not warrant that it is reliable, error-free, or complete. I also do not guarantee that Cinemaniac will meet the user's requirements, be available without interruption, or that the results of using Cinemaniac will be accurate or reliable. The quality of the products, services, information, or other material received through Cinemaniac may not meet the user's expectations, and I do not guarantee that any faults in Cinemaniac will be rectified.</p>
  
  <p>You will use Cinemaniac at your own risk, and you are solely responsible for any damage or loss resulting from your use of Cinemaniac, including damage to their system or loss of data.</p>
  
  
  <h3>Usage Options</h3>
  
  <p>Users do not need to create an account to use Cinemaniac's services. With Cinemaniac, users can discover movies and TV shows, find out info about them, and create their own personal watchlist.</p>
  
  <p>Users also have the ability to create a free Cinemaniac account by signing in with a Google or Apple account. A free Cinemaniac account allows users to access their personal watchlist from any supported mobile device, and to connect with the Cinemaniac app.</p>
  
  
  <h3>Cinemaniac Pro</h3>
  
  <p>In addition to Cinemaniac's free core features, users can access additional features through a six-month subscription called "Cinemaniac Pro". These features include the removal of all ads within the app, accessing to information about where to watch movies and TV shows, unlocking custom categories or lists, unlocking custom themes.</p>
  
  <p>Activation of the Pro features is optional and can only take place via the mobile Cinemaniac app. All paid features are accessible via the mobile Cinemaniac app. The price for a Pro subscription is displayed within the App and includes value-added tax, insofar as applicable.</p>
  
  <p>A subscription to Cinemaniac Pro grants the user access to enhanced digital features for the duration of the contract or as long as they are available. The subscription does not include any physical goods or permanent rights to digital content.</p>
  
  <p>I may alter, add or remove digital content and/or features at any time.</p>
  
  <p>The user can cancel Cinemaniac Pro at any time through the App store, and the subscription will remain active until the end of the current subscription period. After the subscription expires, the Cinemaniac app will revert back to the free version, but the user will still retain their free account.</p>
  
  <p>A subscription will also end if the user's payment is unsuccessful or if I decide to discontinue the Pro option, effective on the next available date.</p>
  
  
  <h3>Liability</h3>
  
  <p>To the fullest extent permitted by law, I exclude all liability for indirect, incidental, consequential, or punitive damages or loss, including lost profits, resulting from or in connection with the use of Cinemaniac, regardless of whether such damages or loss are caused by breach of contract, breach of warranty, or tort (including negligence and product liability), or any other financial loss. It does not matter whether I have been informed of the possibility of such damages or loss.</p>
  
  <p>Cinemaniac may include links to third-party websites, the content of which I have no control over. Therefore, I cannot provide any warranties for external content. The provider or operator of these sites is always responsible for their content. The linked sites were checked for potential violations of law at the time the link was created, and no illegal content was detected.</p>
  
  <p>However, it is not reasonable to expect constant monitoring of the content of linked sites without any specific indication of a violation of the law. If I become aware of any violations of the law, I will remove such links immediately.</p>
  
  
  <h3>Copyright</h3>
  
  <p>Cinemaniac's content is protected by copyright law. Any reproduction, modification, distribution, or exploitation of the content outside of the limits of copyright law requires the written consent of the author.</p>
  
  <p>Downloads and copies of this site are only allowed for personal, non-commercial use.</p>
  
  <p>Downloads and tampering of Cinemaniac apps binaries are not allowed. Any type of direct or indirect intervention to modify the standard behavior of applications is strictly prohibited.</p>
  
  <p>Any attempt to obtain privileges of Cinemaniac Pro without actually being entitled to them is strictly prohibited. This also applies when a valid entitlement has expired.</p>
  
  <p>Where the content on this site has not been created by me, the copyrights of third parties shall be respected. If you become aware of any copyright infringement on Cinemaniac, please let me know. I will remove any content that violates the law immediately upon becoming aware of it.</p>
  
  <h3>Source of data</h3>
  
  <p>All information on the content within the app, regarding movies, TV shows and people, comes from <a href="https://www.themoviedb.org/" target="_blank">The Movie Database</a>'s API services, for which Cinemaniac and the author are not responsible.
  
  <p>Non-exhaustive examples of such content regarding movies, TV shows and people may include titles, names, where to watch, plots, biographies, user ratings, directors, creators, actors, trailers, release dates, spoken languages and original languages, budgets, box office, production companies, production countries, collection membership and collection content, similar and/or related content, most viewed content, currently showing in theaters, currently available for streaming, search results for content.</p>
  
  <p>In the cases previously mentioned, the copyright rules outlined in The Movie Database's <a href="https://www.themoviedb.org/terms-of-use" target="_blank">Terms of Use</a> are to be followed.</p>
  
  <p>This implies that in case of any copyright violation regarding the aforementioned content, the user should directly contact The Movie Database.</p>
  
  <h3>Data Privacy</h3>
  
  <p>I process personal data in accordance with the General Data Protection Regulation (GDPR) and relevant data protection laws. You can find more information about how I process users' personal data in the <a href="https://cinemaniac.app/privacy/">Privacy Policy</a>.</p>
  
  <h3>Miscellaneous</h3>
  
  <p>These Terms of Use are subject to Italian law.</p>
  
  <p>I do not participate in an alternative dispute resolution process with a consumer dispute resolution body and is not legally required to do so.</p>
  
  <p>If any part of the Terms of Use becomes invalid or unenforceable, it will not affect the validity of the remaining terms.</p>

</div>

<hr />

<footer>
  <small>Latest update: Jan 7, 2023</small>
</footer>
`;
