import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {language} from "../../utils/language";
import {Container} from "@mui/material";
import {setEmail} from "../../utils/email";

export function Privacy() {
  const navigate = useNavigate();
  const params = useParams<{path?: string}>();

  const [view, setView] = React.useState<JSX.Element|null>(null);

  React.useEffect(() => {
    document.title = 'Privacy policy – Cinemaniac';
  }, []);

  React.useEffect(() => {
    if (view) {
      setEmail(true);
    }
  }, [view]);

  React.useEffect(() => {
    switch (params.path) {
      case 'it':
      case 'en':
        setView(getView(params.path));
        break;

      default:
        let lang = 'en';

        if (!params.path)
          lang = language === 'it' ? 'it' : 'en';

        navigate({pathname: `/privacy/${lang}/`});
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.path]);

  return view;
}

function getView(lang: 'it' | 'en') {
  return (
    <div className="policy">
      <Container id="privacy" maxWidth="md" dangerouslySetInnerHTML={{__html: require(`./${lang}`)}}></Container>
    </div>
  )
}
