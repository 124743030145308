import React from "react";
import "./NotFound.scss";
import {Container} from "@mui/material";
import {Stores} from "../../components/Stores/Stores";
import {strings} from "../../utils/localization";

export function NotFound() {

  React.useEffect(() => {
    document.title = 'Page not found – Cinemaniac';
  }, []);

  return (
    <div>
      <div className="c-notfound">
        <Container maxWidth="md">
          <h1>Error 404</h1>
          <p>The requested URL was not found</p>
        </Container>
      </div>
      <div className="stores-bg">
        <h2 dangerouslySetInnerHTML={{__html: strings.downloadApp || ''}}></h2>
        <Stores />
      </div>
    </div>
  );
}
