import React from "react";
import "./Contact.scss";
import {Container} from "@mui/material";
import {setEmail} from "../../utils/email";
import {Stores} from "../../components/Stores/Stores";
import {strings} from "../../utils/localization";

export function Contact() {

  React.useEffect(() => {
    document.title = 'Contact – Cinemaniac';
    setEmail();
  }, []);

  return (
    <div>
      <div className="c-contact">
        <Container maxWidth="md">
          <h1>{strings.contact_title}</h1>
          <p>{strings.contact_p1}</p>
          <p>{strings.contact_p2}</p>
          <p>{strings.contact_p3}</p>
          <p dangerouslySetInnerHTML={{__html: strings.contact_p4 || ''}}></p>
        </Container>
      </div>
      <div className="stores-bg">
        <h2 dangerouslySetInnerHTML={{__html: strings.downloadApp || ''}}></h2>
        <Stores />
      </div>
    </div>
  );
}
